import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// Project components
import { ColorMode, Heading, Paragraph, RichText, Wrapper } from "components"

const Contact = () => {
  const { contact } = useStaticQuery(graphql`
    {
      contact: prismicContact {
        data {
          title {
            text
          }
          description {
            html
          }
          email
        }
      }
    }
  `)
  return (
    <ColorMode mode="dark">
      <Wrapper id="contact">
        {contact.data.title && (
          <Heading size={900} mb="layout.4" textAlign={["center", "left"]}>
            {contact.data.title.text}
          </Heading>
        )}
        {contact.data.description && (
          <RichText
            content={{ html: contact.data.description.html }}
            size={600}
          />
        )}
        <Paragraph size={500} mt="layout.4" textAlign={["center", "left"]}>
          <a href={"mailto:" + contact.data.email}>{contact.data.email}</a>
        </Paragraph>
      </Wrapper>
    </ColorMode>
  )
}

export default Contact
