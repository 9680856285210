import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import css from "@styled-system/css"

// Project components
import { Box, Heading, Wrapper } from "components"

// Page component
import Project from "./_project"

function Work({ title }) {
  const { work } = useStaticQuery(graphql`
    {
      work: prismicWork {
        data {
          title {
            text
          }
          projects {
            project {
              document {
                ... on PrismicProject {
                  uid
                  data {
                    project_image {
                      fluid(maxWidth: 1024, maxHeight: 1024) {
                        ...GatsbyPrismicImageFluid
                      }
                      alt
                    }
                    name {
                      text
                    }
                    description {
                      html
                    }
                    services {
                      html
                    }
                    attribution {
                      html
                    }
                    client_logo {
                      fluid(maxWidth: 256, maxHeight: 256) {
                        ...GatsbyPrismicImageFluid
                      }
                      alt
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Wrapper id="work">
      {work.data.title && (
        <Heading
          size={900}
          mb={["layout.4", "layout.6"]}
          textAlign={["center", "left"]}
        >
          {work.data.title.text}
        </Heading>
      )}
      <Box
        css={css({
          hr: {
            ":first-of-type": {
              display: "none",
            },
          },
        })}
      >
        {work.data.projects &&
          work.data.projects.map(({ project }) => (
            <Project
              id={project.document.uid}
              description={
                project.document.data.description &&
                project.document.data.description.html
              }
              services={
                project.document.data.services &&
                project.document.data.services.html
              }
              attribution={
                project.document.data.attribution &&
                project.document.data.attribution.html
              }
              projectImage={
                project.document.data.project_image &&
                project.document.data.project_image.fluid &&
                project.document.data.project_image
              }
              clientLogo={
                project.document.data.client_logo &&
                project.document.data.client_logo.fluid &&
                project.document.data.client_logo
              }
              key={project.document.uid}
            />
          ))}
      </Box>
    </Wrapper>
  )
}

export default Work
