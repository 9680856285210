import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import css from "@styled-system/css"

// Project components
import { AspectRatio, Box, Flex, RichText } from "components"

const Project = ({
  attribution,
  description,
  services,
  horizontal,
  id,
  vertical,
  projectImage,
  clientLogo,
}) => (
  <>
    {/* DIVIDER */}
    <Box
      as="hr"
      width="100%"
      height="1px"
      my={["layout.6", "layout.7"]}
      p={0}
      border={0}
      bg="text"
    />

    {/* CONTENT */}
    <Box id={id} as="article" overflow="hidden">
      <Flex
        flexWrap="wrap"
        css={css({
          m: [-16, null, null, -64],
          "> *": {
            p: [16, null, null, 64],
            width: ["100%", null, null, "50%"],
          },
        })}
      >
        {/* LEFT-TOP */}
        <Flex flexDirection="column">
          {/* TEXT */}
          <RichText content={{ html: description }} size={500} />
          <RichText content={{ html: services }} size={400} mt="layout.4" />
          <RichText content={{ html: attribution }} size={400} mt="layout.2" />

          {/* LOGO */}
          <Box maxWidth={[64, 96]} mt="auto" pt="layout.4">
            <AspectRatio ratio="1:1">
              {clientLogo && (
                <Image
                  fluid={clientLogo.fluid}
                  alt={clientLogo.alt}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  objectFit="contain"
                  objectPosition="center"
                />
              )}
            </AspectRatio>
          </Box>
        </Flex>

        {/* RIGHT-BOTTOM */}
        <Box>
          {/* IMAGE */}
          {projectImage && (
            <Image fluid={projectImage.fluid} alt={projectImage.alt} />
          )}
        </Box>
      </Flex>
    </Box>
  </>
)

export default Project
