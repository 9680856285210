import React from "react"

// Project components
import { SEO } from "components"

// Page sections
import Hero from "./_hero"
import About from "./_about"
import Services from "./_services"
import Work from "./_work"
import Contact from "./_contact"

const IndexPage = () => (
  <>
    <SEO />
    <Hero />
    <About
      title="About"
      body={`<p>We’re an anywhere-based creative collective that finds the emotional connection between brands and people. Like the artists who met at Café Guerbois, we come together to find the meaning of what we do and the joy in working together to make amazing things.</p><p>Our model is a simple innovation in the structure of a marketing agency. We assemble the right team to work on your project and leave behind any of the overhead that complicates projects and inflates budgets.</p>`}
    />
    <Services
      title="Services"
      body={`<p>We’re an anywhere-based creative collective that finds the emotional connection between brands and people. Like the artists who met at Café Guerbois, we come together to find the meaning of what we do and the joy in working together to make amazing things.</p><p>Our model is a simple innovation in the structure of a marketing agency. We assemble the right team to work on your project and leave behind any of the overhead that complicates projects and inflates budgets.</p>`}
    />
    <Work title="Work" />
    <Contact title="Contact" />
  </>
)

export default IndexPage
