import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link as ScrollLink } from "react-scroll"

// Project components
import { Box, ColorMode, Flex, FluidText, Paragraph } from "components"

function Hero() {
  const { opening } = useStaticQuery(graphql`
    {
      opening: prismicOpening {
        data {
          main_title {
            text
          }
          subtitle {
            text
          }
          tagline
          links {
            label
            link
          }
        }
      }
    }
  `)
  return (
    <>
      <ColorMode mode="dark">
        <Flex
          id="hero"
          as="section"
          flexDirection="column"
          justifyContent="center"
          minHeight="calc(var(--vh, 1vh) * 100)"
          px={["layout.4", "layout.8"]}
          py="layout.4"
          bg="background"
        >
          <FluidText
            as="h1"
            size={900}
            mt="auto"
            mb="layout.5"
            pt="layout.7"
            lineHeight="90% !important"
            textAlign="center"
          >
            {opening.data.main_title && (
              <Box children={opening.data.main_title.text} as="span" />
            )}
            <br />
            {opening.data.subtitle && (
              <Box
                children={opening.data.subtitle.text}
                as="span"
                fontSize="1.125em"
                fontFamily="sans"
                fontWeight={300}
              />
            )}
          </FluidText>

          <Paragraph
            as="div"
            size={300}
            mt="auto"
            letterSpacing="0.05em"
            textAlign="center"
            css={{ textTransform: "uppercase" }}
          >
            <Box as="p" display="inline" m={0}>
              {opening.data.tagline}
            </Box>
            <Box as="span" display={["none", null, null, "inline"]}>
              {" · "}
            </Box>
            <Box as="nav" display={["block", null, null, "inline"]}>
              {opening.data.links &&
                opening.data.links.map(({ link, label }, index) => (
                  <>
                    <ScrollLink
                      to={link}
                      smooth={true}
                      css={{ cursor: "pointer" }}
                    >
                      {label}
                    </ScrollLink>
                    {index !== opening.data.links.length - 1 ? " / " : ""}
                  </>
                ))}
            </Box>
          </Paragraph>
        </Flex>
      </ColorMode>
    </>
  )
}

export default Hero
