import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// Project components
import { AccordionGroup, Box, Heading, RichText, Wrapper } from "components"

function Services() {
  const { services } = useStaticQuery(graphql`
    {
      services: prismicServices {
        data {
          title {
            text
          }
          services {
            name {
              text
            }
            offering {
              html
            }
          }
        }
      }
    }
  `)
  return (
    <Wrapper id="services">
      {services.data.title && (
        <Heading
          as="h2"
          size={900}
          mb={["layout.4", "layout.6"]}
          textAlign={["center", "left"]}
        >
          {services.data.title.text}
        </Heading>
      )}
      {services.data.services && (
        <Box>
          <AccordionGroup
            items={services.data.services.map(({ name, offering }, index) => ({
              heading: name.text,
              children: (
                <RichText content={{ html: offering.html }} maxWidth={640} />
              ),
            }))}
          />
        </Box>
      )}
    </Wrapper>
  )
}

export default Services
