import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import css from "@styled-system/css"

// Project components
import { Heading, RichText, Wrapper } from "components"

function About() {
  const { about } = useStaticQuery(graphql`
    {
      about: prismicAbout {
        data {
          title {
            text
          }
          description {
            html
          }
        }
      }
    }
  `)
  return (
    <Wrapper id="about">
      {about.data.title && (
        <Heading
          as="h2"
          size={900}
          mt={[0, "0.125em"]}
          mr={[0, "layout.6"]}
          mb="layout.4"
          textAlign="center"
          css={css({ float: ["none", "left"] })}
        >
          {about.data.title.text}
        </Heading>
      )}
      {about.data.description && (
        <RichText content={{ html: about.data.description.html }} size={600} />
      )}
    </Wrapper>
  )
}

export default About
